@use "@angular/material" as mat;
@import "./theme/lib/style/color";

@include mat.core();

$app-primary: mat.define-palette($md-mcgpalette0, 500);
$app-accent: mat.define-palette(mat.$teal-palette, A200);
$app-warn: mat.define-palette(mat.$red-palette);

//@include mat.button-color($custom-theme);

$typography: mat.define-typography-config(
  $font-family: "Montserrat",
);

$theme: mat.define-light-theme(
    (
      color: (
        primary: $app-primary,
        accent: $app-accent,
        warn: $app-warn,
      ),
      typography: $typography,
    )
);

@include mat.all-component-themes($theme);
.mat-drawer.mat-drawer-side {
  z-index: 1!important;
  background-color: #FBFBFB !important;
}

html, body {
  height: 100%;
}

* {
  font-family: 'Montserrat';
  --mdc-dialog-container-shape: 12px;
}

svg {
  vertical-align: top;
}

.error-message {
  color: #ed6c68;
}

label {
  color: #727688;
  font-size: 14px;
}

.disabled_none {
  pointer-events: none !important;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-SemiBold.ttf');
}
.border-top{
  border-top-color: #EAEBEF !important;
}


.light-gray {
  color: #727688;
  font-size: 14px;
}

.pointer{
  cursor: pointer;
}
.back-button{
  width: fit-content;
  cursor: pointer;
  margin-bottom: 40px;
  mat-icon{
    width: 8px;
    margin-right: 12px;
  }
  > div{
    font-size: 14px;
    font-weight: 700;
    color: #727688;
  }
}

button:disabled {
  cursor: not-allowed;
  border: none;
  background: #E0E0E0 !important;
  color: #fff;
  opacity: 1 !important;
}

.material-icons {
  font-family: 'Material Icons' !important;
}

.mdc-checkbox .mdc-checkbox_native-control:enabled:checked~.mdc-checkbox_background{
  background-color: #ed6c68 !important;
  border-color: #ed6c68 !important;
}
.mdc-checkbox .mdc-checkbox_native-control:enabled~.mdc-checkboxbackground .mdc-checkbox_checkmark{
  color: #fff !important;
}
.mat-mdc-tab-body-content{
  overflow: hidden !important;
}
.tabs-card .mat-mdc-form-field-infix{
  width: 200px;
}
.tabs-card .first_dropdown .mat-mdc-form-field-infix{
  width: 170px;
}

.card-shadow{
  box-shadow: 4px 8px 24px 0px #304DBB2E !important;
}

textarea {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  border: none;
  border-bottom: 1px solid #EAEBEF;
  border-radius: 8px;
  padding: 14px 16px;
  transition: 0.1s ease-out;
  margin: 0;
  line-height: 1;
}

.associated-card-modal{
  .associated-card-item{
    .status-wrapper{
      background-image:  unset !important;
    }
  }
}

.ls-normal {
  letter-spacing: normal;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

//--------------------------------------------
// CARD ART DIALOG TRANSITION RIGHT TO LEFT
//--------------------------------------------
@keyframes slide {
  100% { right: 0; }
}

.dialog-side-panel {
  position: fixed !important;
  bottom: 0;
  top: 0;
  right: -100vw;
  width: 100vw;
  height: 100%;
  animation: slide 0.5s forwards;
  animation-delay: 0.1s;
}

.fw-600 {
  font-weight: 600!important;
}

.fw-500 {
  font-weight: 500!important;
}

.custom {
  &-input {
    border: 1px solid #ECEFF3;
    border-radius: 32px;
    padding: 4px 16px;
    outline: none;
    color: #36394A;
    font-weight: 600;
    height: 2rem;

    &-hint {
      font-size: 10px;
      color: #818898;
    }
  }
}

.description {

  &-title {
    color: #818898;
    margin-bottom: 12px;
    font-size: 10px;
    letter-spacing: 1px;
  }
  &-content {
    font-size: 18px;
    color: #2D365C;
    font-weight: 600;
  }
  &-text {
    font-size: 14px;
    color: #2D365C;
    font-weight: 600;
  }
}

.card-status-modal {
  margin-top: 60px;
  margin-left: 60px;
}
